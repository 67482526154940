<template>
    <div>
      <el-tabs v-model="activeTab">
        <el-tab-pane v-for="(item, index) in tabs" :label="item.label" :name="item.name" :key="index">
          <p>{{ item.content }}</p>
          <button @click="editTabLabel(index)">Edit</button>
          <button @click="deleteTab(index)">Delete</button>
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeTab: '', // 当前选中的tab名
        tabs: []
      };
    },
    created() {
      // 在组件创建时从后端获取tab页数组
      this.fetchTabs();
    },
    methods: {
      fetchTabs() {
        // 从后端获取tab页数组的逻辑
        // 这里使用示例数据代替后端接口调用
        setTimeout(() => {
          this.tabs = [
            { label: 'Tab 1', name: 'tab1', content: 'Content of Tab 1' },
            { label: 'Tab 2', name: 'tab2', content: 'Content of Tab 2' },
            { label: 'Tab 3', name: 'tab3', content: 'Content of Tab 3' }
          ];
        }, 1000);
      },
      editTabLabel(index) {
        // 编辑tab标签名称的逻辑
        // 这里可以弹出对话框或在页面上直接修改标签名称
        const newLabel = prompt('Enter new tab label:');
        if (newLabel) {
          this.tabs[index].label = newLabel;
        }
      },
      deleteTab(index) {
        // 删除tab页的逻辑
        this.tabs.splice(index, 1);
        if (this.activeTab === this.tabs[index]?.name) {
          this.activeTab = ''; // 如果删除的是当前选中的tab，则清空activeTab
        }
      }
    }
  };
  </script>